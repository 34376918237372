import styled from "styled-components"
import media from "../../theme/breakpoints"

export const PostList = styled.div`
  display: grid;
  grid-gap: 2.8rem;
  grid-template-columns: repeat(3, 1fr);
  margin-top: 1rem;

  ${media.lg`
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem;
  `}

  ${media.sm`
    grid-template-columns: repeat(1, 1fr);
  `}
`
