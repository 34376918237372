import { graphql } from "gatsby"
import React from "react"
import useAllPosts from "../hooks/useAllPosts"
import { useAllCategories } from "../hooks/useAllCategories"
import Layout from "../components/Layout"
import PostCard from "../components/PostCard"
import Divider from "../components/Divider"
import { PostList } from "./homepage/style"

import getCategories from "../util/getCategories"

const Homepage = ({ data, location }) => {
  const allPosts = useAllPosts()
  const allCategories = useAllCategories()
  const postData = getCategories(allPosts, allCategories)

  return (
    <Layout
      location={location}
      type="main"
      title={data.site.siteMetadata.title}
      seoTitle="Home"
      description={data.site.siteMetadata.description}
      tagline={data.site.siteMetadata.tagline}
    >
      <h3>Latest Posts</h3>
      <PostList>
        {postData.map((post, i) => (
          <PostCard
            key={post.id}
            data={post}
            size={i === 0 ? "large" : "small"}
          />
        ))}
      </PostList>
      <Divider />
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
        tagline
      }
    }
  }
`

export default Homepage
