import { graphql, useStaticQuery } from "gatsby"

const useAllPosts = () => {
  const { allMdx } = useStaticQuery(
    graphql`
      query ALL_POSTS_QUERY {
        allMdx(
          filter: { frontmatter: { collection: { eq: "blog" } } }
          sort: { fields: [frontmatter___date], order: DESC }
          limit: 1000
        ) {
          edges {
            node {
              id
              excerpt(pruneLength: 110)
              fields {
                filename
              }
              frontmatter {
                date(formatString: "MMMM DD, YYYY")
                title
                path
                categories
                image {
                  childImageSharp {
                    fluid(maxWidth: 1000) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
              timeToRead
            }
          }
        }
      }
    `
  )
  return allMdx.edges
}

export default useAllPosts
